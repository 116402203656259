import React from 'react'
import PropTypes from 'prop-types'
import './App.css'
import { FeaturesProvider } from '../FeatureToggle'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from '../../pages/Home'
import { NotFound } from '../../pages/Error'
import { AuthProvider } from '../OAuth/Provider'
import UserProvider from '../User/UserProvider'
import OAuth from '../OAuth'
import Installations from '../../pages/Installations'
import { ThemeProvider } from '@mui/material'
import { theme } from '../theme'
import Journals from '../../pages/Journals/Journals'

function App({ features }) {
  const [windowDimension, detectHW] = React.useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  })

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    })
  }

  React.useEffect(() => {
    window.addEventListener('resize', detectSize)

    return () => {
      window.removeEventListener('resize', detectSize)
    }
  }, [windowDimension])

  return (
    <FeaturesProvider features={features}>
      <AuthProvider
        authorizeUrl={process.env.REACT_APP_AUTH_URL + '/authorize'}
        tokenUrl={process.env.REACT_APP_AUTH_URL + '/token'}
        clientId="frontend"
        scope="common"
        redirectPath="/oauth"
      >
        <UserProvider>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <div className="app">
                <Routes>
                  <Route exact path="/" element={<Home />} />
                  <Route exact path="/oauth" element={<OAuth />} />
                  {features.includes('STATS_INSTALLATIONS') ? (
                    <Route
                      exact
                      path="/stats/installations"
                      element={<Installations />}
                    />
                  ) : null}
                  {features.includes('STATS_DATA') ? (
                    <Route exact path="/stats/data" element={<Home />} />
                  ) : null}
                  {features.includes('UPDATE_APPS') ? (
                    <Route exact path="/update/apps" element={<Home />} />
                  ) : null}
                  {features.includes('UPDATE_BUILDS') ? (
                    <Route exact path="/update/builds" element={<Home />} />
                  ) : null}
                  {features.includes('SONARS_JOURNALS') ? (
                    <Route
                      exact
                      path="/sonars/journals"
                      element={<Journals />}
                    />
                  ) : null}
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </div>
            </BrowserRouter>
          </ThemeProvider>
        </UserProvider>
      </AuthProvider>
    </FeaturesProvider>
  )
}

App.propTypes = {
  features: PropTypes.array.isRequired,
}

export default App
