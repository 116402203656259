import React, { useEffect } from 'react'
import SystemMenu from '../../shared/ui/SystemMenu'
import { useAuth } from '../../app/OAuth/Provider'
import { Box } from '@mui/material'

function Home() {
  const { isAuthenticated, getToken, login } = useAuth()

  useEffect(() => {
    getToken()
      .then((r) => {})
      .catch((r) => login())
  }, [])

  useEffect(() => {
    if (!isAuthenticated) login()
  }, [isAuthenticated])

  return (
    <SystemMenu>
      <h1>Обзор</h1>
      <Box>Здесь будет краткая информация из всех разделов</Box>
    </SystemMenu>
  )
}

export default Home
