import { createTheme } from '@mui/material'

export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#ffcc00', light: '#f9a700', dark: '#e38900' },
  },
  components: {
    ListItemText: {
      styleOverrides: {
        root: {
          color: 'red',
          fontWeight: '900',
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          background: 'none',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '0 20px 10px',
          borderRadius: '20px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: 'space-around',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        textPrimary: {
          color: '#e38900',
        },
      },
    },
  },
})
