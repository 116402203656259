import React, { useContext, useRef } from 'react'
import { Alert, Box } from '@mui/material'
import { BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts'
import StatsContext from '../../app/Stats/StatsContext'
import Moment from 'moment'
import { useContainerDimensions } from '../../shared/useContainerDimensions'
import PropTypes from 'prop-types'

const segmentToDate = (str) => {
  const a = str.split(/\D+/)
  return new Date(a[0], a[1] - 1, a[2], a.length > 3 ? a[3] : 0)
}

const buildItem = (dt, count = 0, format = 'Y-MM-D') => {
  return {
    name: Moment(dt).locale('ru').format(format),
    timestamp: dt instanceof Date ? dt.getTime() : dt,
    count: count,
  }
}

function Chart({ segmentSize }) {
  const { data, loading, error } = useContext(StatsContext)

  const step = segmentSize

  let start = new Date()
  let end = new Date()
  if (data.length > 0) {
    let dt = segmentToDate(data[0].segment)
    start = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate())
    dt = segmentToDate(data[data.length - 1].segment)
    end = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 0)
  }

  let wideData = [
    ...data.map((p) => {
      const dt = segmentToDate(p.segment)
      return buildItem(dt, p.count)
    }),
  ]
  if (wideData.length === 0 || wideData[0].timestamp !== start.getTime()) {
    wideData = [buildItem(start), ...wideData]
  }
  if (wideData[wideData.length - 1].timestamp !== end.getTime()) {
    wideData.push(buildItem(end))
  }

  let last = null
  const gdata = []
  for (const item of wideData) {
    if (last !== null) {
      for (let t = last + step; t < item.timestamp; t += step) {
        gdata.push(buildItem(t))
      }
    }
    gdata.push(item)
    last = item.timestamp
  }

  const ref = useRef(null)
  const { width } = useContainerDimensions(ref)

  return (
    <Box ref={ref}>
      {error != null && (
        <Alert variant="outlined" severity="error">
          {error}
        </Alert>
      )}
      {loading}

      <BarChart
        width={width}
        height={300}
        data={gdata}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Bar
          dataKey="count"
          fill="#56c"
          background={{ fill: 'rgba(0,0,0,0.05)' }}
        />
      </BarChart>
    </Box>
  )
}

Chart.propTypes = {
  segmentSize: PropTypes.number,
}

export default Chart

/*
      <LineChart width={width} height={300} data={gdata}>
        <XAxis dataKey="name" />
        <YAxis dataKey="count" />
        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
        <Tooltip />
        <Line type="monotone" dataKey="count" stroke="#8884d8" />
      </LineChart>
 */
