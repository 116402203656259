import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import PropTypes from 'prop-types'

function ConfirmDialog(props) {
  const handleClose = () => {
    props.onHide()
  }

  const handleAccept = () => {
    handleClose()
    if (props.accept != null) props.accept()
  }

  const handleReject = () => {
    handleClose()
    if (props.reject != null) props.reject()
  }

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {props.title === '' ? null : (
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      )}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAccept}>Да</Button>
        <Button onClick={handleReject} autoFocus>
          Отмена
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmDialog.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  accept: PropTypes.func,
  reject: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
}

export default ConfirmDialog
