function isBlobResponse(response) {
  if (!response) return false
  const type = response.headers.get('content-description')
  return type && type.includes('File Transfer')
}

function fileNameFromHeaders(response) {
  if (!response) return ''
  const disp = response.headers.get('content-disposition')
  const res = disp.match(/filename="(.*)"/)
  if (!res) return ''
  return decodeURI(res[1])
}

export { isBlobResponse, fileNameFromHeaders }
export default isBlobResponse
